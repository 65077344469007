import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import PleaseWaitContextProvider from './context/PleaseWaitContextProvider.js';
import BookingDetailContextProvider from './context/BookingDetailContextProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <BookingDetailContextProvider>
    <PleaseWaitContextProvider>
      <App />
    </PleaseWaitContextProvider>
    </BookingDetailContextProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
